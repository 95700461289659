<template>
  
  
  <div class="columns mt-6">
    <div class="column" v-bind:class="{'offset-menu': menuOpen}">
      <div class="container">
         <highcharts :options="pieData"></highcharts>
      </div>
    </div>
  </div>
</template>
<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  data: function() {
    return {
      searchResult: [],
      valgteLokaliteter: [],
      valgteLokaliteterIds: [],
      searchParameters: {},
      selectText: 'Velg',
      deselectText: 'Fjern',
      menuOpen: true,
      grafDataForLokaliteter: {},
      pieData : {
           chart: {
            type: "pie",
          },
          title: {
            text: "Fordeling"
          },
           series: []
     }
    };
  },
  components: {
    highcharts: Chart,
  },
  name: "GraphPie",
  mounted() {
    this.configChart();
    this.$root.$on("showFordelingGraphResult", (data) => {
       
      this.pieData.series = [];
       
      var serie = { name: 'Antall: ', data: [] }
      data.data.forEach(lokData => {
        //serie.data.push([lokData.name, lokData.value, lokData.colour]);
        serie.data.push({
                  name: lokData.name,
                  y: lokData.value,
                  color: lokData.colour
                });
      });
      
      this.pieData.series.push(serie);
    });

    this.$root.$on('menuStatus', status => {
        this.menuOpen = status
    });

    this.$root.$on('searchParameters', data => {
      if (data) {
        this.searchParameters = data;
      } 
    })

  },
  computed: {
    getResult() {
      return this.result;
    },
  },
  methods: {
    configChart() {
      Highcharts.setOptions({
          chart: {
            type: "pie",
            plotShadow: false
          },
          plotOptions: {
                fillOpacity: 0.6

          },
        });
    }
  }
}

</script>

<style scoped>
.offset-menu {
  margin-left: 450px;
}
</style> 
