<template>
  <div id="app">
    <div class="fullscreen">
       <div class="loader-wrapper is-success" :class="{ 'is-active': loaderActive }">
          <div class="loader is-loading"></div>
        </div>    
        <left-menu @showView="ChangeT($event)" />
        <big-map v-show="visning === 'map'" />
        <result-table v-show="visning === 'table'" />
        <result-graph v-show="visning === 'graph' & !visFordeling" />
        <result-graph-pie v-show="visning === 'graph' & visFordeling" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import BigMap from "./components/Map.vue";
import LeftMenu from "./components/LeftMenu.vue";
import ResultTable from "./components/Table.vue";
import ResultGraph from "./components/Graph.vue";
import ResultGraphPie from "./components/GraphPie.vue";
import toastr from 'toastr'

Vue.config.errorHandler = (err, vm, info) => {
    toastr.error("En feil har skjedd på klienten: " + err + info);
};

Vue.config.warnHandler = function (err, vm, info) {
  toastr.error("En feil har skjedd på klienten: " + err + info);
};

export default {
  name: "App",
  data: function() {
    return {
      visFordeling: true,
      visMeny: true,
      visning: "map",
      loaderActive: false
    };
  },
  components: {
    BigMap,
    LeftMenu,
    ResultTable,
    ResultGraph,
    ResultGraphPie
  },
  mounted() {
    let self = this
    // self.$root.$on('changeView', view => {
    //     self.visning = view
    // });
    this.$root.$on('showLoader', status => {
        self.loaderActive = status
    });
    this.$root.$on('fordeling', value => {
        self.visFordeling = value
    });
  },
  methods: {
    ChangeT(view) {
      this.visning = view;
    },
    load() {
      this.isActive = true;
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="toastr/build/toastr.min.css"></style>

<style>
#app .tabs.is-toggle li.is-active a {
  background-color: #31547e;
}

#app .multiselect__tag, #app .multiselect__option--highlight, #app .multiselect__option--highlight, #app .multiselect__option--highlight:after, #app .multiselect__tag-icon:after,#app .multiselect__tag-icon:hover  {
  background-color: #20a49a;
  background: #20a49a;
}

#app .multiselect__option--selected.multiselect__option--highlight, #app .multiselect__option--selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    
}


#app .tabs.is-toggle li.is-selected a {
    background-color: lightgrey;
    color: #20a49a;
    font-weight: bolder;
}

.fullscreen {
  height: 100vh;
}
.loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
   height: 100%;
    width: 100%;
    background: #fff;
    opacity: 0;
    z-index: -1;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 10px;
}
.loader {
    height: 60px;
    width: 60px;

}
.loader-wrapper.is-active {
        opacity: 0.6;
        z-index: 2;
        background-color: gray;
    }
</style>
