<template>
  <div class="columns">
    <div class="column" v-bind:class="{'offset-menu': menuOpen}">
      <div class="container">
        <div v-for="input in searchResult" v-bind:key="input.Lokalitetsnavn">
          <highcharts :options="input"></highcharts>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";

export default {
  data: function() {
    return {
      searchResult: [],
      menuOpen: true
    };
  },
  components: {
    
    highcharts: Chart,
  },
  name: "Graph",
  mounted() { 
    this.$root.$on("showTimeseriesGraphResult", (data) => {
       this.configChart();
       this.searchResult = data;
    });

    this.$root.$on('menuStatus', status => {
        this.menuOpen = status
    });
  },
  computed: {
    getResult() {
      return this.result;
    },
  },

  methods: {
    configChart() {
      Highcharts.setOptions({
        chart: {
          type: 'line'
        },
        xAxis: {
       allowDecimals: false
      },
      yAxis: {
          title: {
              enabled: true,
              text: "Bestandsindeks (&aring;r 1=100)",
          },
          labels: {
              x: -10
          },
          min: 0,
      }
 });
    }
  }
};
</script>

<style scoped>
body {
  background-color: red
}
.offset-menu {
  margin-left: 450px;
}
</style> 
