import Vue from 'vue'
import App from './App.vue'
import "bulma/css/bulma.css";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import offlineexporting from "highcharts/modules/offline-exporting";
import loadHighchartsMore from 'highcharts/highcharts-more';
import moment from 'moment'

Vue.config.productionTip = false

Vue.filter('formatDate', function(value) {
    if (value) {
      return moment(String(value)).format('DD.MM.YYYY')
    }
  })

Vue.filter('telleenhetNavn', function(value) {
    if (value === 0) return "Ukjent"
    if (value === 1) return "Tilsynelatende okkupert reir/reirplass"
    if (value === 2) return "Tilsynelatende traffikert reirgang"
    if (value === 3) return "Par"
    if (value === 4) return "Kull"
    if (value >= 5 || value <=8 ) return "Individer"
    if (value === 9) return "Egg"
    return ""
}) 

Vue.filter('sesongNavn', function(value) {
  if (value === 1) return "Sommer"
  if (value === 2) return "Vinter"
  if (value === 3) return "Myting"
  return ""
}) 


exporting(Highcharts);
offlineexporting(Highcharts);
loadHighchartsMore(Highcharts)


//Global config graphs
//  Highcharts.setOptions({
//    xAxis: {
//        allowDecimals: false
//       },
//       yAxis: {
//           title: {
//               enabled: true,
//               text: "Bestandsindeks (&aring;r 1=100)",
//           },
//           labels: {
//               x: -10
//           },
//           min: 0,
//       },
//       legend: {
//          layout: 'vertical',
//          align: 'right',
//          TextAlign: 'left',
//          verticalAlign: 'top',
//          itemStyle: {
//              width: 140
//          },
//      },
//  });


new Vue({
  render: h => h(App),
}).$mount('#app')

Vue.use(Highcharts);
