<template>
  <div style="height: 100%">
    <div id="map" style="height: 100%">
      <map-overlay v-if="showOverlay" :parameters="overlayParameters"></map-overlay>
    </div>
  </div>
</template>
<script>
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import { createMapIconPie } from "../helpers/chart-factory.js";
  import icon from 'leaflet/dist/images/marker-icon.png'
  import iconShadow from 'leaflet/dist/images/marker-shadow.png' 
  import MapOverlay from './MapOverlay.vue'

  export default {
    name: "Map",
    data: function() {
      return {
        map: {},
        locationLayer: new L.LayerGroup(),
        searchResult: [],
        timeSeriesResult: [],
        showOverlay: false,
        overlayParameters: null,
        searchParameters: {} 
      };
    },
    components: { MapOverlay },
    mounted() {
      var self = this;

      self.map = L.map("map").setView([63, 10], 5);

      new L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { maxZoom: 25, maxNativeZoom: 18, zoomControl: false }).addTo(
        self.map
      );

      new L.TileLayer("https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4&zoom={z}&x={x}&y={y}").addTo(this.map);

      self.map.zoomControl.remove();
      L.control.zoom({ position: 'bottomright'}).addTo(self.map);

      var blueIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconAnchor: [12, 41],
        popupAnchor: [0, -41]
      });

      self.$root.$on("searchParameters", data => {
          self.searchParameters = data
      });

      self.$root.$on('showTimeseriesMapResult', pos => {
        self.showOverlay = false;
        self.timeSeriesResult = pos
        self.locationLayer.clearLayers() 
        self.locationLayer.addTo(self.map) 
         if (self.timeSeriesResult.length > 0) {
          self.timeSeriesResult.forEach(pos => {
            if (pos.latitude != null && pos.longitude != null) {
              L.marker([pos.latitude, pos.longitude], { icon: blueIcon, kommuneId: pos.kommuneId, id:  pos.loknr})
              .bindTooltip(pos.lokalitetsnavn,  { permanent: true,  direction: 'right' })
              .on('click', self.loadOverlay() ) 
              .addTo(self.locationLayer);
            } else {
              console.log("no lat/long");
            }
          });   
          self.map.invalidateSize();
          self.map.flyToBounds(self.timeSeriesResult.map(({latitude, longitude})=>{  return([latitude, longitude]) }), {maxZoom: 8});
         }
      });

      self.$root.$on('showFordelingMapResult', data => {
          self.showOverlay = false;
          self.searchResult = data
          self.locationLayer.clearLayers() 
          self.locationLayer.addTo(self.map) 

          let counter = 0; 

          if (self.searchResult.length > 0) {
              self.searchResult.forEach(lok => {
              if (lok.latitude != null && lok.longitude != null) {
                var chartName = "chartObject" + counter;
                counter = counter + 1;
                var pieData = [];
                lok.data.forEach(lokData => {
                  pieData.push({
                    name: lokData.name,
                    y: lokData.value,
                    color: lokData.colour
                  });
                });
                
                var myIcon = new L.divIcon({
                  className: 'chartobject transparent overflow ',
                  html: "<div id='" + chartName + "' class='chartobjectHolder' style='width:" + lok.iconSize + "; height:" + lok.iconSize + "; overflow: visible;'></div>",
                  iconSize: [lok.iconSize, lok.iconSize]
                });

                L.marker([lok.latitude, lok.longitude], { icon: myIcon, draggable: false, riseOnHover: true }).addTo(self.locationLayer);
                createMapIconPie(chartName, pieData, lok.iconSize, lok.telleenhet, lok.name);
              } else {
                console.log("no lat/long");
              }
            });
            
            self.map.invalidateSize();
            self.map.flyToBounds(this.searchResult.map(({latitude, longitude})=>{  return([latitude, longitude])}), {maxZoom: 8});
            }
      })
    },
    methods: { 
      loadOverlay() {
        let self = this
        return function (e) {

          self.overlayParameters = {
            datatypeId: self.searchParameters.datatypeId,
            sesong: self.searchParameters.sesong, 
            arter: self.searchParameters.arter,
            kommuneId: parseInt(e.target.options.kommuneId),
            lokalitetsnr: e.target.options.id
          };

          self.showOverlay = true;
        }
      }
    }  
  };
</script>

<style lang="css" scoped>
  @import "../../node_modules/leaflet/dist/leaflet.css";

  body {
    padding: 0;
    margin: 0;
  }

  #map {
    height: 90%;
    width: 100%;
    font-weight: bold;
    font-size: 13px;
    text-shadow: 0 0 2px #fff;
    z-index: 1;
  }
</style>
