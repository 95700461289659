<template>
  <div class="columns">
    <div class="column" v-bind:class="{'offset-menu': menuOpen}">
      <div class="container">
        <!-- Siste obs og høyeste antall -->
        <table class="table is-fullwidth is-bordered is-striped mt-6" v-if="datatypeId == 1 || datatypeId == 2">
          <thead>
            <tr>
              <th>Sesong</th>
              <th>Art</th>
              <th>Dato</th>
              <th>Kommune</th>
              <th>Lokalitet</th>
              <th>Antall</th>
              <!-- <th>Grunnlagsdata</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(res, index) in searchResult" :key="index">
              <td> {{ res.sesong | sesongNavn}}</td>
              <td>{{res.artNavn}}</td>
              <td>{{ res.dato | formatDate }}</td>
              <td>{{res.kommuneNavn}} {{res.lokalitetKommuneName}}</td>
              <td>{{ res.lokalitetName }}</td>
              <td>{{ res.antall}}</td>
               <!-- <td> <span v-if="res.opprinneligAntall">Omregnet fra: {{ res.opprinneligAntall}}</span>  <strong>Telleenhet:</strong>{{ res.telleenhetVerdi }} <strong>Kjønn:</strong> {{res.kjonnVerdi}} <strong>Status: </strong> {{res.statusVerdi}}</td> -->
            </tr>
          </tbody>
        </table>
        <!-- Estimat -->
          <table class="table is-fullwidth is-bordered is-striped mt-6" v-if="datatypeId == '3'">
          <thead>
            <tr>
              <th>Sesong</th>
              <th>Kommune</th>
              <th>Art</th>
              <th>Fra - Til </th>
              <th>Lokalitet</th>
              <th>Antall lokaliteter</th>
              <th>Estimat</th>
              <th>Estimeringsår</th>
              
            </tr>
          </thead>
          <tbody>
            <tr v-for="(res, index) in searchResult" :key="index">
              <td> {{ res.sesong | sesongNavn}}</td>
               <td>{{res.navn}} </td>
              <td>{{res.artNavn}}</td>
              <td>{{ res.fra }} - {{ res.til }} </td>
              <td>{{ res.lokalitetName }}</td>
              <td> {{ res.antLokaliteter}}</td>
              <td>{{ res.antall}}</td>
              <td>{{ res.estyr}}</td>
            </tr>
          </tbody>
        </table>
          <!-- Bestandsutvikling -->
       <table class="table is-fullwidth is-bordered is-striped mt-6" v-if="datatypeId == '4'">
          <thead>
            <tr>
               <th>Sesong</th>
              <th>Art</th>
              <th>År</th>
              <th>Kommune</th>
              <th>Lokalitet</th>
              <th>Telleenhet</th>
              <th>Index </th>
              <th>ST Error </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="res in timesSeriesResult" :key="res.id">
              <td> {{ res.sesong | sesongNavn}}</td>
              <td>{{res.artsnavn}}</td>
              <td>{{ res.aar }}</td>
              <td>{{res.kommunenavn}} </td>
              <td>{{ res.lokalitetsnavn }}</td>
              <td>{{ res.telleenhet | telleenhetNavn }}</td>
              <td>{{ res.index }}</td>
              <td>{{ res.stError}} </td>
            </tr>
          </tbody>
        </table> 


         <!-- Overlevelse -->
       <table class="table is-fullwidth is-bordered is-striped mt-6" v-if="datatypeId == '5'">
          <thead>
            <tr>
               <th>Sesong</th>
              <th>Art</th>
              <th>År</th>
              <th>Kommune</th>
              <th>Lokalitet</th>

              <th>Overlevelse </th>
              <th>SE</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="res in timesSeriesResult" :key="res.id">
              <td> {{ res.sesong | sesongNavn}}</td>
              <td>{{res.artNavn}}</td>
              <td>{{ res.til }}</td>
              <td>{{res.navn}} </td>
              <td>{{ res.lokalitet }}</td>
              <td>{{ res.overlevelseResultat }}</td>
              <td>{{ res.se}} </td>
            </tr>
          </tbody>
        </table> 
        <!-- Hekkesuksess -->
       <table-hekkesuksess v-show="datatypeId == '6'" />
     
        
      </div>
    </div>
  </div>
</template>
<script>
import TableHekkesuksess from "./HekkesuksessTable.vue";

export default {
  data: function() {
    return {
      searchResult: [],
      timesSeriesResult: [],
      menuOpen: true,
      kommunefordelt: false,
      datatypeId: 0
    };
  },
  name: "Table",
  props: {
    msg: String,
  },
  components: {
     TableHekkesuksess
  },
  mounted() {

    this.$root.$on('showFordelingTableResult', data => {
      this.searchResult = data
    });
     this.$root.$on('showTimeseriesTableResult', (data) => {
        this.timesSeriesResult = data;
    });

    this.$root.$on('menuStatus', status => {
        this.menuOpen = status
    });

    this.$root.$on('kommunefordelt', status => {
        this.kommunefordelt = status
    });

    this.$root.$on('datatype', datatypeId => {
        this.datatypeId = datatypeId
    });
  }
  
};
</script>
<style scoped>
.offset-menu {
  margin-left: 450px;
}
</style>
