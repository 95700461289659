<template>
  <div>
    <button class="button menu-open" @click="toggleMenu(true)">
      <span class="icon is-small"><i class="fas fa-angle-double-right"></i></span>
    </button>
    <aside class="menu left-menu" v-show="visMeny">
      <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <button class="button menu-close" @click="toggleMenu(false)">
            <span class="icon is-small"><i class="fas fa-angle-double-left"></i></span>
          </button>
          <a class="navbar-item" href="https://www.seapop.no" >
            <img 
              :src="getImgUrl('SeapopLogoSmall')"
              alt="SEAPOP: Om sjøfugl – for et rikere hav"
              style="max-height:60px !important"
            />
          </a>
        </div>
      </nav>

      <div class="columns search-parameter-group">
        <div class="column">
          <!-- <h3 class="is-size-5 mb-3">Vis datatype</h3> -->
          <div class="tabs is-toggle is-fullwidth is-small mb-3">
            <ul>
              <li :class="[visFordeling ? 'is-active' : '']" @click="fordeling(true)">
                <a>
                  <span>Fordeling</span>
                </a>
              </li>
              <li :class="[!visFordeling ? 'is-active' : '']" @click="fordeling(false)">
                <a>
                  <span>Tidsserier</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="select is-fullwidth">
            <select v-model="datatypeId">
              <option :value="1" v-show="visFordeling">Siste observasjoner</option>
              <option :value="2" v-show="visFordeling">Høyeste antall</option>
              <option :value="3" v-show="visFordeling">Estimerte verdier</option>
              <option :value="4" v-show="!visFordeling">Bestandsutvikling</option>
              <option :value="5" v-show="!visFordeling">Overlevelse</option>
              <option :value="6" v-show="!visFordeling">Hekkesuksess</option>
            </select>
          </div>
        </div>
      </div>

       <div class="columns search-parameter-group">
        <div class="column">
          <!-- <h3 class="is-size-5 mb-3">Sesong</h3> -->
          <div class="tabs is-toggle is-fullwidth is-small mb-3">
            <ul>
              <li :class="[selectedTimePeriod === 'sommer' ? 'is-active' : '']" @click="changeTimePeriod('sommer')">
                <a>
                  <span>Sommer</span>
                </a>
              </li>
              <li :class="[selectedTimePeriod === 'vinter' ? 'is-active' : '']" @click="changeTimePeriod('vinter')">
                <a>
                  <span>Vinter</span>
                </a>
              </li>
              <li
                v-show="visFordeling"
                :class="[selectedTimePeriod === 'myting' ? 'is-active' : '']"
                @click="changeTimePeriod('myting')"
              >
                <a>
                  <span>Myting</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="control" style="height: 24px">
            <div  v-show="selectedTimePeriod === 'sommer'" v-if="visFordeling && datatypeId < 3 ">
              <label class="radio" > <input type="radio" id="hekkende" v-model="hekkende" value="true"> Hekkende </label>
              <label class="radio"> <input type="radio" id="ikkehekkende" v-model="hekkende" value="false"> Ikke hekkende </label>
            </div>
          </div>
        </div>
      </div> 
      <div class="columns search-parameter-group">
        <div class="column geografisk-utvalg">
        
        <div class="columns mb-1">
          <div class="column is-2 label">
            <span>Fylke:</span>
          </div>
          <div class="column">
             <div class="select is-fullwidth is-multiple mb-1">
             <multiselect v-model="valgteFylker" :options="fylker" :multiple="true" 
            :close-on-select="true" :clear-on-select="true" :preserve-search="false" placeholder="Velg fylker" label="name" 
            track-by="id" :preselect-first="false" :select-label="selectText" :deselect-label="deselectText" :selected-label="'Valgt'"></multiselect>
          </div>
          </div>
        </div>

        <div class="columns mb-1">
          <div class="column is-2 label">
            <span>Komm:</span>
          </div>
          <div class="column">
              <div class="select is-fullwidth is-multiple mb-1">
                <multiselect v-model="valgteKommuner" :options="kommuner" :multiple="true" :disabled="false"
                :close-on-select="true" :clear-on-select="true" :preserve-search="false" placeholder="Velg kommuner" label="name" 
                track-by="id" :preselect-first="false" :select-label="selectText" :deselect-label="deselectText" :selected-label="'Valgt'"></multiselect>
              </div>
          </div>
        </div>

         <div class="columns mb-1">
          <div class="column is-2 label">
            <span>Lokalitet:</span>
          </div>
          <div class="column">
             <div class="select is-fullwidth is-multiple mb-1" >
              <multiselect v-model="valgteLokaliteter" :options="lokaliteter" :multiple="true" :disabled="lokaliteter.length == 0" 
              :close-on-select="true" :clear-on-select="true" :preserve-search="false" placeholder="Velg lokaliteter" label="name" 
              track-by="id" :preselect-first="false" :select-label="selectText" :deselect-label="deselectText" :selected-label="'Valgt'"></multiselect>
             </div>
          </div>
        </div>

         <div class="columns mb-1">
          <div class="column is-2 label">
            <span>Verneomr:</span>
          </div>
          <div class="column">
               <div class="select is-fullwidth is-multiple mb-1" >
             <multiselect v-model="valgteVerneomrader" :options="verneomrader" :multiple="true" :disabled="verneomrader.length == 0"
            :close-on-select="true" :clear-on-select="true" :preserve-search="false" placeholder="Velg verneområder" label="name" 
            track-by="id" :preselect-first="false" :select-label="selectText" :deselect-label="deselectText" :selected-label="'Valgt'"></multiselect>
         </div>
          </div>
        </div>

          <div class="columns mb-1">
          <div class="column is-2 label">
            <span>Hav:</span>
          </div>
          <div class="column">
              <div class="select is-fullwidth is-multiple">
             <multiselect v-model="valgteHavomrade" :options="havomrader" :disabled="false" :multiple="false"
            :close-on-select="true" :clear-on-select="true" :preserve-search="false" placeholder="Velg havområder" label="name" 
            track-by="id" :preselect-first="false" :select-label="selectText" :deselect-label="deselectText" :selected-label="'Valgt'"></multiselect>
          </div>
          </div>
        </div>
       </div>

      </div>
      <div class="columns art-utvalg search-parameter-group">
        <div class="column is-2 label">
            <span>Art:</span>
          </div>
        <div class="column">
          <!-- <h3 class="is-size-5 mb-3">Sjøfugl</h3> -->
          <div class="select is-fullwidth is-multiple">
            <multiselect v-model="valgteArter" :options="arter" :multiple="true"  @input="updateArter"
            :close-on-select="true" :clear-on-select="true" :preserve-search="false" placeholder="Velg arter" label="navn" 
            track-by="navn" :preselect-first="true" :select-label="selectText" :deselect-label="deselectText" :selected-label="'Valgt'"></multiselect>
          </div>
        </div>
      </div>
      <div class="columns search-parameter-group">
        <div class="column">
           <!-- <h3 class="is-size-5 mb-3">Søk og vis resultat </h3> -->
          <div class="tabs is-toggle is-fullwidth">
            <ul>
              <li :class="{'is-active' : !searchFilterChanged && selectedView == 'map', 'is-selected' : searchFilterChanged && selectedView == 'map'}" @click="changeTab('map')">
                <a>
                  <span class="icon is-small"><i class="far fa-map"></i></span>
                  <span>Kart</span>
                </a>
              </li>
              <li :class="{'is-active' : !searchFilterChanged && selectedView == 'table', 'is-selected' : searchFilterChanged && selectedView == 'table'}" @click="changeTab('table')">
                <a>
                  <span class="icon is-small"><i class="fas fa-list"></i></span>
                  <span>Liste</span>
                </a>
              </li>
              <li v-show= "datatypeId != 6" :class="{'is-active' : !searchFilterChanged && selectedView == 'graph', 'is-selected' : searchFilterChanged && selectedView == 'graph'}" @click="changeTab('graph')">
                <a>
                  <span class="icon is-small"><i class="fas fa-chart-area"></i></span>
                  <span>Graf</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
           
    </aside>
  </div>
</template>
<script>
import axios from "axios";
import toastr from "toastr"
import Multiselect from 'vue-multiselect';
import {SEAPOP_CONFIG}  from '@/../config.js';

const axiosInst = axios.create();
axiosInst.interceptors.response.use((response) => response, (error) => {
  toastr.error("En feil skjedde ved henting av data fra serveren");
  throw error;
});

export default {
  components: { Multiselect },
  name: "LeftMenu",
  data: function() {
    return {
      visFordeling: true,
      visMeny: true,
      selectedView: "map",
      selectedTimePeriod: "sommer",
      selectedLoc: "kommune",
      hekkende: "true",
      datatypeId: 1,
      arter: [],
      kommuner: [],
      fylker: [],
      lokaliteter: [],
      verneomrader: [],
      havomrader: [],
      valgteKommuner: [],
      valgteArter: [],
      valgteFylker: [],
      valgteLokaliteter: [],
      valgteVerneomrader: [],
      valgteHavomrade: null,
      selectText: 'Velg',
      deselectText: 'Fjern',
      searchFilterChanged: true
      
    };
  },
  mounted() {
    let self = this

    this.getArter();
    axiosInst.get(SEAPOP_CONFIG.API_URL + "/Parameter/Getkommuner").then( res => {
      self.kommuner = res.data
    })
    axiosInst.get(SEAPOP_CONFIG.API_URL + "/Parameter/GetFylker").then( res => {
      self.fylker = res.data
    })
    axiosInst.get(SEAPOP_CONFIG.API_URL + "/Parameter/GetHavomrader").then( res => {
      self.havomrader = res.data
    })

    
  },
  computed: {
    fylkeEllerKommuneValgt () {
      return this.valgteKommunerIds.length > 0 || this.valgteFylkerIds.length > 0
    }
  },
  watch: {
    valgteHavomrade(newValue) {
      if(newValue) {
        this.valgteKommuner = [];
        this.valgteFylker = [];
        this.valgteLokaliteter = [];
        this.valgteVerneomrader = [];
        this.searchFilterChanged = true
      }
    }, 

    valgteFylker(newValue) {
      var self = this;
      if(newValue.length > 0) {
        this.valgteHavomrade = null;
      }
      self.searchFilterChanged = true
      self.oppdaterKommuner();
      self.oppdaterLokaliteter();
      self.oppdaterVerneomrader();
    },

    valgteKommuner(newValue) {
      var self = this;
      if(newValue.length > 0) {
        self.valgteHavomrade = null;
        self.searchFilterChanged = true
      }
      self.searchFilterChanged = true
      self.oppdaterLokaliteter();
      self.oppdaterVerneomrader();
    },

    valgteLokaliteter(newValue) {
      if(newValue.length > 0)  {
          this.valgteVerneomrader = [];
          this.valgteHavomrade = null;
          this.searchFilterChanged = true
      }
    },

    valgteVerneomrader(newValue) {
      if(newValue.length > 0) {
        this.valgteLokaliteter = [];
        this.valgteHavomrade = null;
        this.searchFilterChanged = true
      }
    },
    selectedTimePeriod(newValue) {
      if (newValue) {
        this.searchFilterChanged = true
      }
    },
    hekkende (newValue) {
      if (newValue) {
        this.searchFilterChanged = true
      }
    },
    datatypeId (newValue) {
       if (newValue) {
        this.searchFilterChanged = true
      }
    }
  },
  methods: {
    getImgUrl(path) {
      var images = require.context('../assets/')
      return SEAPOP_CONFIG.ROOT_URL + images( "./" + path + ".png")
    },
    changeTab(name) {
      this.selectedView = name;
      this.$emit("showView", name);
      this.searchWithParameters();
    },
    changeTimePeriod(name) {
      this.selectedTimePeriod = name;
      this.searchFilterChanged = true
      // this.searchWithParameters();
    },

    oppdaterKommuner() {
      let self = this;

      var fylkeIder = (self.valgteFylker || []).map(f => f.id);

      axiosInst.post(SEAPOP_CONFIG.API_URL + "/Parameter/GetGeoUtvalg", { type: "kommune", fylker: fylkeIder }).then( res => {
          self.kommuner = res.data
          if (self.valgteKommuner != null) {
            self.valgteKommuner = self.valgteKommuner.filter(vk => self.kommuner.some(k => k.id == vk.id));
          }
        });
    },

    oppdaterLokaliteter() {
      let self = this;
      
      var fylkeIder = (self.valgteFylker || []).map(f => f.id);
      var kommuneIder = (self.valgteKommuner || []).map(k => k.id);

      if(fylkeIder.length > 0 || kommuneIder.length > 0) {
        axiosInst.post(SEAPOP_CONFIG.API_URL + "/Parameter/GetGeoUtvalg", { type: "lokalitet", fylker: fylkeIder, kommuner: kommuneIder }).then( res => {
          self.lokaliteter = res.data;
          if (self.valgteLokaliteter != null) {
            self.valgteLokaliteter = self.valgteLokaliteter.filter(vl => self.lokaliteter.some(l => l.id == vl.id));
          }
        });
      }
      else {
        self.lokaliteter = [];
        self.valgteLokaliteter = [];
      }
    },

    oppdaterVerneomrader() {
      let self = this;
      
      var fylkeIder = (self.valgteFylker || []).map(f => f.id);
      var kommuneIder = (self.valgteKommuner || []).map(k => k.id);

      if(fylkeIder.length > 0 || kommuneIder.length > 0) {
        axiosInst.post(SEAPOP_CONFIG.API_URL + "/Parameter/GetGeoUtvalg", { type: "verneomrade", fylker: fylkeIder, kommuner: kommuneIder }).then( res => {
          self.verneomrader = res.data;
          if (self.valgteVerneomrader != null){
            self.valgteVerneomrader = self.valgteVerneomrader.filter(vv => self.verneomrader.some(v => v.id == vv.id));
          }
        });
      }
      else {
        self.verneomrader = [];
        self.valgteVerneomrader = [];
      }
    },

    fordeling(value) {
      this.visFordeling = value;
      if (value) {
        this.datatypeId = 1;
      } else {
        this.datatypeId = 4;
      } 
      this.$root.$emit("fordeling", value);

    },
    toggleMenu(status) {
      this.visMeny = status;
      this.$root.$emit("menuStatus", status);
    },
    updateArter(valgteArter) {
      let ids = [];
      valgteArter.forEach((art) => {
        ids.push(art.id)
      });
      this.valgteArterIds = ids;
      this.searchFilterChanged = true 

    },
    
    getSesongId() {
      var sesongId = 1;
      if (this.selectedTimePeriod == "vinter") {
        sesongId = 2;
      }
      if (this.selectedTimePeriod == "myting") {
        sesongId = 3;
      } 
      return sesongId;
    },
    getArter () {
      let self = this
      axiosInst.get(SEAPOP_CONFIG.API_URL + "/Parameter/GetArterFiltrert/?datatypeId=" + self.datatypeId).then( res => {
        self.arter = res.data
      })
    },  

    searchWithParameters() {
      let self = this;
      if (self.datatypeId === 0) {
         toastr.warning("Du må velge en datatype")
        return;
      }
      if (!self.visFordeling) {
        self.getArter();
      }
      
      if (self.valgteArter.length == 0 && (self.valgteKommuner.length == 0 && self.valgteFylker.length == 0) && self.valgteHavomrade == null) {
        toastr.warning("Du må velge en lokasjon eller en art")
        return;
      }

      self.$root.$emit("datatype", this.datatypeId);

      var searchParameters = {
        datatypeId: parseInt(this.datatypeId),
        sesong: this.getSesongId(),
        arter: self.valgteArterIds,
        kommuner: self.valgteKommuner != null ? self.valgteKommuner.map(k => k.id) : [],
        geoUtvalg: self.selectedLoc,
        visning: self.selectedView,
        fylker: self.valgteFylker != null ? self.valgteFylker.map(f => f.id) : [],
        erHekkende: self.hekkende == "true",
        verneomrader: (self.valgteVerneomrader || []).map(v => v.id),
        lokaliteter: self.valgteLokaliteter,
        havomradeId: self.valgteHavomrade != null ?  self.valgteHavomrade.id : null
      };

      self.$root.$emit("searchParameters", searchParameters);
      self.$root.$emit("showLoader", true);
      self.searchFilterChanged = false;
      
      if (self.visFordeling) { 
            axiosInst.post(SEAPOP_CONFIG.API_URL + "/Fordeling/GetFordeling", searchParameters).then((res) => {            
              if (res.data.length == 0) {
                toastr.info("Ingen treff for dette søket");
              } 
              if (res.data.length == 400) {
                toastr.info("For mye data. Viser de 400 største bestandene. Filtrer ytterligere for mer detaljer.");
              }
              if (self.selectedView == 'graph') {
                self.$root.$emit("showFordelingGraphResult", res.data);
              } else if (self.selectedView == 'table') {
                self.$root.$emit("showFordelingTableResult", res.data);
              }else {
              self.$root.$emit("showFordelingMapResult", res.data);
              }
            })
            .finally(function() {
              self.$root.$emit("showLoader", false);
            });

      } else {
        axiosInst
          .post(SEAPOP_CONFIG.API_URL + "/Tidsserie/GetTidsserie", searchParameters).then((res) => {
            
          if (res.data.length == 0) {
            toastr.info("Ingen treff for dette søket.");
          }
          if (self.selectedView == 'map') {
            self.$root.$emit("showTimeseriesMapResult", res.data);    
          }
          else if(self.selectedView == 'table') {
            if (self.datatypeId == "6") {
              self.$root.$emit("showHekkefuglResult", res.data);
            } else {
                self.$root.$emit("showTimeseriesTableResult", res.data);
            }
          }
          else if (self.selectedView == 'graph') {
            if(self.datatypeId == "6") {
              toastr.warning("Det finnes ingen graf for hekkesuksess.")
            }
            else {
              self.$root.$emit("showTimeseriesGraphResult", res.data);
            }
          }

          })
          .finally(function() {
              self.$root.$emit("showLoader", false);
          });
      }
    },
  },
};
</script>


<style>
.multiselect .multiselect__placeholder {
  color:  #4a4a4a !important;
}

</style>
<style scoped>

.search-parameter-group {
  background-color: #f8f7f7;
  margin: 10px !important;
}
.left-menu {
  position: absolute;
  top: 0;
  width: 450px;
  height: 100vh;
  z-index: 30;
  background-color: white;
}

.left-menu .tabs li 
{
  background-color: white;
}

.menu-close {
  position: absolute;
  right: 0;
  margin: 4px;
}
.menu-open {
  position: absolute;
  top: 0;
  margin: 4px;
  z-index: 2;
}
.columns {
  margin: 0;
}
.geografisk-utvalg .column {
  padding: 0;
  font-size: 14px;
}
.geografisk-utvalg .label, .art-utvalg .label {
  vertical-align: middle;
  line-height: 40px;
}
.tab-disabled {
  color: #b5b5b5 !important;
  pointer-events: none;
}
.multiselect .multiselect__placeholder {
  color:  #4a4a4a !important;
}

@media screen and (max-width: 450px) {
  .left-menu {
    width: 100%;
  }
}
</style>
