<template>
   <div style="background-color: white; z-index: 410" class="is-overlay">
        <div style="position: absolute; top: 5px; right: 25px;" @click="$parent.showOverlay = false;">Lukk</div>
        <div id="chart" v-if="showContent" style="margin-top: 40px; margin-left: 450px; padding: 40px;" >
            <hekkesuksess-table v-if="parameters.datatypeId == 6" :result="data"></hekkesuksess-table>
            <highcharts v-else :options="data"></highcharts>
        </div>
      </div> 
</template>
<script>
    import Highcharts from "highcharts";
    import axios from "axios";
    import toastr from "toastr";
    import {SEAPOP_CONFIG}  from '@/../config.js';
    import { Chart } from "highcharts-vue";
    import HekkesuksessTable from './HekkesuksessTable.vue';
    const axiosInst = axios.create();

    axiosInst.interceptors.response.use((response) => response, (error) => {
        toastr.error("En feil skjedde ved henting av data fra serveren");
        throw error;
    });

    export default {
        name: "MapOverlay",
        props: {
            parameters: {type: Object, required: true }
        },
        data: function() {
            return {
                searchResult: [],
                showContent: false,
                //datatypeId: 6,
                data: null
            };
        },
        components: { highcharts: Chart, HekkesuksessTable },
        mounted() {
            var self = this;
            axiosInst.post(SEAPOP_CONFIG.API_URL + "/Tidsserie/GetGrafTilKart", self.parameters).then((res) => {
                self.data = res.data;

                if(self.parameters.datatypeId != 6) {
                    self.configChart();
                }

                self.showContent = true;
            });
         },
        methods: {
            configChart() {
                Highcharts.setOptions({
                    chart: { type: 'line' },
                    xAxis: { allowDecimals: false },
                    yAxis: {
                        title: {
                            enabled: true,
                            text: "Bestandsindeks (&aring;r 1=100)"
                        },
                        labels: { x: -10 },
                        min: 0
                    }
                });
            }
        }
    }  
</script>