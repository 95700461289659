var api_url = "";
var root_url = "";

switch (process.env.NODE_ENV) {
  case "development":
    api_url = "https://localhost:44328";
    root_url = "";
    break;
  case "production":
    api_url = "https://demo-seapop.nina.no/api";
    root_url = "";
    break;
}

export const SEAPOP_CONFIG = {
  API_URL: api_url,
  ROOT_URL: root_url
};
