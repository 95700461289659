<template>
    <table class="table is-fullwidth is-bordered is-striped mt-6" v-if="tabellData && tabellData.år">
        <thead>
            <tr>
                <th></th>
                <th v-for="år in tabellData.år" :key="år" >
                    {{år}}
                </th>
            </tr>
        </thead>
        <tbody>
            <template v-for="lok in tabellData.lokaliteter">
                <tr :key="lok.lokalitet">
                    <td colspan="99"> {{ lok.lokalitet }}</td>
                </tr>
                <tr v-for="art in lok.arter" :key="`l_${lok.lokalitet}-a_${art.artId}`">
                    <td>{{ art.artnavn }}</td>
                    <td v-for="(data, index) in art.data" :key="`l_${lok.lokalitet}-a_${art.artId}-${index}`" :class="getClassForData(data)">
                        {{data}}
                    </td>
                </tr>
            </template>
        </tbody>
    </table> 
</template> 

<script>
    export default {
    props: {
        result: { type: Object, required: false }
    },
    data() {
        return {
            tabellData: this.result
        }
    },
    mounted() {
        this.$root.$on('showHekkefuglResult', result => {
            this.tabellData = result
        });
    },
    methods: {
        getClassForData(data) {
            if(data.indexOf("M") != -1) return "data-m";
            if(data.indexOf("G") != -1) return "data-g";
            if(data.indexOf("D") != -1) return "data-d";
            if(data.indexOf("?") != -1) return "data-u";
        }
    }

    }  
</script>

<style scoped>
    .data-g { background-color: green; }
    .data-m { background-color: orange; }
    .data-d { background-color: red; }
    .data-u { background-color: lightgrey; }
</style>