import Highcharts from "highcharts";

export function createMapIconPie(chartName, data, size, telleenhet, lokalitet){
    
    new Highcharts.Chart({
        lang: {
            closeChartButtonTitle: 'Close chart'
        },
        chart: {
            type: 'pie',
            animation: false,
            backgroundColor: null,
            renderTo: chartName,
            width: size,
            height: size,
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            size: '100%',
            events: {}
        },
        credits: false,
        title: { text: null },
        plotOptions: {
            pie: {
                allowPointSelect: false,
                cursor: 'pointer',
                dataLabels: {
                  enabled: false,
                },
                zIndex: 1000000,
                showInLegend: false,
                //innerSize: '40%',
                size: '100%',
                shadow: false,
                borderWidth: 1,
                opacity: 0.8
            }
        },
        tooltip: {
            useHTML: true,
            shared: false,
            enabled: true,
            outside: true,
            style: {
                width: 200
            },
            formatter: function () {
                return '<strong>' +  lokalitet + '</strong><br/>' + this.point.name + ': ' + this.point.y + ' ' + telleenhet;
            }
        },
        series: [{
            type: 'pie',
            name: 'Antall',
            data: data,
            size: '100%',
            animation: false,
            showInLegend: false
        }],
        navigation: {
            buttonOptions: { enabled: false }
        },
            /* legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top'
            } */
    });
  }